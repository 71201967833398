import Siema from 'siema';

Siema.prototype.preventLinkClick = function (selector) {
    const CONTAINER = this.selector.parentNode;
    const LINKS = CONTAINER.querySelectorAll(selector);

    LINKS.forEach(el => {
        let firstMouseX = 0;

        el.addEventListener('mousedown', (e) => firstMouseX = e.clientX);

        el.addEventListener('click', (e) => {
            let lastMouseX = e.clientX;
            let diffMouseX = firstMouseX - lastMouseX;

            if(diffMouseX > 1 || diffMouseX < -1) {
                e.preventDefault();
            }
        });
    });
}

const $featured_content_carousel = document.querySelector('.js-featured-content-carousel');

function disableButton(button) {
	if(button) {
		button.classList.add('is-disabled');
		button.disabled = true;
	}
}

function enableButton(button) {
	if(button) {
		button.classList.remove('is-disabled');
		button.disabled = false;
	}
}

if ( $featured_content_carousel ) {

    const prevButton = document.querySelector('.js-featured-content-carousel-previous'),
          nextButton = document.querySelector('.js-featured-content-carousel-next');

	const featuredContentCarousel = new Siema({
	    selector: '.js-featured-content-carousel',
		perPage: 1,
		onInit() {
            this.preventLinkClick('li');

            disableButton(prevButton);
        },
        onChange: () => {
            if(featuredContentCarousel.currentSlide > 0) {
				enableButton(prevButton);
			} else {
				disableButton(prevButton);
			}

			if(featuredContentCarousel.currentSlide == featuredContentCarousel.perPage) {
				disableButton(nextButton);
			} else {
				enableButton(nextButton);
			}
        }
	});

	prevButton.addEventListener('click', () => featuredContentCarousel.prev());
	nextButton.addEventListener('click', () => featuredContentCarousel.next());

}
