
import { makeBodyNonScrollable, makeBodyScrollable } from './components.site-body-scroll';
import PubSub from '../modules/modules.pubsub';
import anime from 'animejs';

const siteMask = document.querySelector('.js-site-mask');
const pubsub = new PubSub();

export function showMask() {

    anime({
        targets: siteMask,
        opacity: .9,
        easing: 'cubicBezier(0.250, 0.100, 0.250, 1.000)',
        duration: 300,
        complete: () => {
            siteMask.classList.remove('is-hidden');
        }
    });
    
    addCloseMaskOnClick();
    makeBodyNonScrollable();
}

export function hideMask() {
    
    anime({
        targets: siteMask,
        opacity: 0,
        easing: 'cubicBezier(0.250, 0.100, 0.250, 1.000)',
        duration: 300,
        complete: () => {
            siteMask.classList.add('is-hidden');
        }
    });

    makeBodyScrollable();
}

function handleMaskClick() {
    hideMask();
    pubsub.publish('site-mask.closed');

    removeCloseMaskOnClick();
}

export function addCloseMaskOnClick() {
    siteMask.addEventListener('click', handleMaskClick);
}

export function removeCloseMaskOnClick() {
    siteMask.removeEventListener('click', handleMaskClick);
}