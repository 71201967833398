
	[...document.querySelectorAll( '.js-resources-search' )].forEach( ( search ) => {
		const 	input = search.querySelector( '.js-input' ),
				form = search.querySelector( '.js-form' );

		if ( input && form ) {
			search.classList.add( 'active' );

			form.addEventListener( 'submit', ( event ) => {
				event.preventDefault();
				
				window.location.href = `/hub/?search=${ input.value }`
			} );
		}
	} );