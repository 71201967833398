
	function init_videos () {
		[ ...document.querySelectorAll( '.js-youtube-player' ) ].forEach( player_wrap => {
			let player;

			const 	player_container = player_wrap.querySelector( '.js-player' ),
					pause = player_wrap.querySelector( '.js-pause' ),
					play = player_wrap.querySelector( '.js-play' );

			player = new YT.Player( player_container, {
				videoId: player_container.dataset.src,
				playerVars: {
					'playsinline' : 1,
					'controls' : 0
				},
				events: {
					'onStateChange': () => {
						const state = player.getPlayerState();

						if ( state == 0 ) {
							player.seekTo( 0 );
							player.pauseVideo();
							player_wrap.classList.remove( 'playing' );
							player_wrap.classList.add( 'paused' );
						} else if ( state == 1 ) {
							player_wrap.classList.add( 'playing' );
							player_wrap.classList.remove( 'paused' );
						} else if ( state == 2 ) {
							player_wrap.classList.remove( 'playing' );
							player_wrap.classList.add( 'paused' );
						}
					}
				}
			} );

			play.addEventListener( 'click', e => player.playVideo() );
			pause.addEventListener( 'click', e => player.pauseVideo() );
		} );
	}

	window.onYouTubeIframeAPIReady = function  () {
		init_videos()
	}

	let tag = document.createElement('script');
	tag.src = "https://www.youtube.com/iframe_api";

	let firstScriptTag = document.getElementsByTagName('script')[0];
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);


	

	
