

	const 	accordions = [...document.querySelectorAll('.js-accordion')],
			classes = {
				active : 'active'
			};

	let prev_window_width = window.innerWidth;

	function init () {
		accordions.forEach( ( accordion ) => {
			const accordion_items = [...accordion.querySelectorAll('.js-accordion-item')];

			let open_accordion = 0;

			accordion_items.forEach( ( accordion_item ) => {
				const 	accordion_trigger = accordion_item.querySelector('.js-accordion-trigger'),
						accordion_content = accordion_item.querySelector('.js-accordion-content'),
						accordion_content_inner = accordion_item.querySelector('.js-accordion-content-inner');

				accordion_trigger.addEventListener('click', event => toggle_item( accordion_item ) );

				remove_active_attrs( accordion_item );

				function close_any_open_accordion () {
					if ( open_accordion !== null ) remove_active_attrs( accordion_items[ open_accordion ] );
				}

				function remove_active_attrs ( item ) {
					item.classList.remove( classes.active );
					accordion_content.style.height = '0px';
				}

				function toggle_item ( accordion_item ) {
					const this_accordion_index = accordion_items.indexOf( accordion_item );

					close_any_open_accordion();

					accordion_content.style.height = ( ( this_accordion_index === open_accordion ? '0' : accordion_content_inner.offsetHeight ) + 'px' );
					accordion_item.classList.add( classes.active );

					if ( this_accordion_index === open_accordion ) {
						open_accordion = null;
						accordion_item.classList.remove( classes.active );
					} else {
						open_accordion = accordion_items.indexOf( accordion_item );
						accordion_item.classList.add( classes.active );
					}
				}
			} );			
		} );
	}

	init();

	window.addEventListener('resize', ( event ) => {
		if ( window.innerWidth !== prev_window_width ) {
			prev_window_width = window.innerWidth;

			init();
		}
	} );
