import Siema from 'siema';

Siema.prototype.preventLinkClick = function (selector) {
    const CONTAINER = this.selector.parentNode;
    const LINKS = CONTAINER.querySelectorAll(selector);

    LINKS.forEach(el => {
        let firstMouseX = 0;

        el.addEventListener('mousedown', (e) => firstMouseX = e.clientX);

        el.addEventListener('click', (e) => {
            let lastMouseX = e.clientX;
            let diffMouseX = firstMouseX - lastMouseX;

            if(diffMouseX > 1 || diffMouseX < -1) {
                e.preventDefault();
            }
        });
    });
}

const $service_carousel = document.querySelector('.js-images-carousel');

if ( $service_carousel ) {

	$service_carousel.classList.remove('no-carousel');

	const serviceCarousel = new Siema({
	    selector: '.js-images-carousel',
	    perPage: {
	        0: 2.2,
	        768: 3.2,
	        1024: 4.2,
	        1280: 5
		},
		onInit() {
			this.preventLinkClick('li');
		}
	});

	document.querySelector('.js-images-carousel-previous').addEventListener('click', () => serviceCarousel.prev( get_slide_amount() ));
	document.querySelector('.js-images-carousel-next').addEventListener('click', () => serviceCarousel.next( get_slide_amount() ));

	function get_slide_amount () {

		const docWidth = document.documentElement.clientWidth;

		if ( docWidth < 768 ) {
			return 1;
		} else if ( docWidth < 1024 ) {
			return 2;
		}

		return 3;
	}

}
