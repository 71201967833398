
import anime from 'animejs';
import COMMON_SETTINGS from '../settings/settings.common-settings';
import { mmMatches } from '../utils/utils.matchmedia';
import { showMask } from './components.site-mask';
import PubSub from '../modules/modules.pubsub';

const 	pubsub = new PubSub();

const 	siteSearchButton = document.querySelector('.js-site-search-button'),
		siteSearch = document.querySelector('.js-site-search');

let 	searchLabel, searchFormElems, searchFormCloseButton, searchInput;

function showLabel() {
	return anime({
		targets: searchLabel,
		opacity: 1,
		translateY: [-10, 0],
		easing: 'cubicBezier(0.250, 0.100, 0.250, 1.000)',
		duration: 250
	});
}

function showFormElems() {
	anime({
		targets: searchFormElems,
		opacity: 1,
		translateY: [-10, 0],
		easing: 'cubicBezier(0.250, 0.100, 0.250, 1.000)',
		duration: 250,
		complete: () => {
			siteSearch.classList.add('is-visible');
		}
	});
}

function showClosebutton() {
	anime({
		targets: searchFormCloseButton,
		opacity: 1,
		easing: 'cubicBezier(0.250, 0.100, 0.250, 1.000)',
		duration: 1000
	});
}

function hideElems() {
	
	anime({
		targets: searchLabel,
		opacity: 0,
		translateY: [0, -10],
		easing: 'cubicBezier(0.250, 0.100, 0.250, 1.000)',
		duration: 250
	});

	anime({
		targets: searchFormElems,
		opacity: 0,
		translateY: [0, -10],
		easing: 'cubicBezier(0.250, 0.100, 0.250, 1.000)',
		duration: 250,
		complete: () => {
			siteSearch.classList.remove('is-visible');
		}
	});

	anime({
		targets: searchFormCloseButton,
		opacity: 0,
		easing: 'cubicBezier(0.250, 0.100, 0.250, 1.000)',
		duration: 250
	});
}

function focusSearchInput() {
	searchInput.focus();
}

if ( siteSearchButton && siteSearch ) {
	
	searchLabel = siteSearch.querySelector('.js-label');
	searchFormElems = siteSearch.querySelector('.js-form-elems');
	searchFormCloseButton = siteSearch.querySelector('.js-close-button');
	searchInput = siteSearch.querySelector('input[name=s]');

	siteSearchButton.addEventListener('click', e => {
		
		showMask();
		showLabel().finished.then( () => {
			showFormElems()
			showClosebutton();
			focusSearchInput();
		} );
	});

	pubsub.subscribe('site-mask.closed', function() {
		hideElems();
	});
}


