
const 	attention_bars = document.querySelectorAll('.js-attention-bar');

let		email_input = null,
		gdpr_block = null;

attention_bars.forEach( function ( attention_bar ) {

	email_input = attention_bar.querySelector('.js-attention-bar-email-input');
	gdpr_block = attention_bar.querySelector('.js-attention-bar-gdpr-block');

	if ( email_input && gdpr_block ) {

		gdpr_block.style.display = 'none';

		email_input.addEventListener('click', function () {

			gdpr_block.style.display = 'flex';

		})

	}

})
