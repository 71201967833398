import Siema from 'siema';

Siema.prototype.preventLinkClick = function (selector) {
    const CONTAINER = this.selector.parentNode;
    const LINKS = CONTAINER.querySelectorAll(selector);

    LINKS.forEach(el => {
        let firstMouseX = 0;

        el.addEventListener('mousedown', (e) => firstMouseX = e.clientX);

        el.addEventListener('click', (e) => {
            let lastMouseX = e.clientX;
            let diffMouseX = firstMouseX - lastMouseX;

            if(diffMouseX > 1 || diffMouseX < -1) {
                e.preventDefault();
            }
        });
    });
}

const latest_feed_carousels = document.querySelectorAll('.js-latest-feed-carousel');

[...latest_feed_carousels].forEach( function ( latest_feed_carousel ) {

	if ( latest_feed_carousel && latest_feed_carousel.childElementCount > 3 ) {

		const latest_feed_items = latest_feed_carousel.querySelectorAll('.js-latest-feed-item');

		set_heights();

		const latestFeedCarousel = new Siema({
		    selector: '.js-latest-feed-carousel',
		    perPage: {
		        0: 1,
		        768: 2,
		        1024: 3
			},
			onInit() {
				this.preventLinkClick('li');
			}
		});

		document.querySelector('.js-latest-feed-carousel-previous').addEventListener('click', () => latestFeedCarousel.prev());
		document.querySelector('.js-latest-feed-carousel-next').addEventListener('click', () => latestFeedCarousel.next());

		latest_feed_carousel.classList += ' is-carousel';

		window.addEventListener('resize', set_heights)

		function set_heights( Latest_feed_items ) {

			const latest_feed_items_max_height = get_heights( latest_feed_items );

			[...latest_feed_items].forEach( function ( latest_feed_item ) {

				latest_feed_item.style.height = `${latest_feed_items_max_height}px`;

			} )

		}

		function get_heights ( latest_feed_items ) {

			let placeholder_arr = [];

			[...latest_feed_items].forEach( function ( latest_feed_item ) {

				placeholder_arr.push( latest_feed_item.offsetHeight );

			} )

			return Math.max( ...placeholder_arr );

		}

	}		
	
} )




