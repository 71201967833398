import Siema from 'siema';
import COMMON_SETTINGS from '../settings/settings.common-settings';
import debounce from 'lodash/debounce';

const 	this_carousels = [...document.querySelectorAll('.js-carousel')],
		classes = {
			carousel_active: 	'is-carousel',
			is_active: 			'active',
			is_disabled: 		'is-disabled',
		};


function init () {
	this_carousels.forEach( function ( this_carousel ) {
		const this_carousel_list = this_carousel.querySelector('.js-carousel-list');

		if ( this_carousel_list ) {
			const 	carousel_item_count = this_carousel_list.children.length,
					carousel_items = [...this_carousel_list.children],
					carousel_dots_container = this_carousel.querySelector('.js-carousel-dots'),
					carousel_prev = this_carousel.querySelector('.js-carousel-prev'),
					carousel_next = this_carousel.querySelector('.js-carousel-next');

			let		start_index = 0,
					previous_window_width = window.innerWidth,
					previous_slide_index = start_index,
					carousel;

			if ( carousel_item_count > get_items_per_page() ) init();

			function init () {
				this_carousel.classList.remove( classes.carousel_active );

				carousel = new Siema( {
			        selector: this_carousel_list,
			        duration: 200,
			        easing: 'ease-out',
			        startIndex: start_index,
			        perPage: get_items_per_page(),
			        draggable: false,
			        loop: false,
			        onInit: () => {
			            if ( carousel_dots_container ) build_counters();
			            if ( carousel_prev ) disableButton( carousel_prev );
			            carousel_items[ start_index ].classList.add( classes.is_active );
			            this_carousel.classList.add( classes.carousel_active );

			            equal_height_items();
			        },
			        onChange: () => {
			            if ( carousel_dots_container ) update_dots();

			            previous_slide_index = Math.ceil( carousel.currentSlide / get_items_per_page() );

			            carousel_items.forEach( function ( carousel_item ) { carousel_item.classList.remove( classes.is_active ) } );
						carousel_items[ carousel.currentSlide ].classList.add( classes.is_active );
			        }
			    } );
			} 
			

			carousel_prev.addEventListener( 'click', function ( event ) {
				const new_slide = carousel.currentSlide - 1;

				carousel.goTo( new_slide );

				if ( new_slide === 0 ) disableButton( carousel_prev );
				enableButton( carousel_next );
		    } );

		    carousel_next.addEventListener( 'click', function ( event ) {
		    	const new_slide = carousel.currentSlide + 1;

				carousel.goTo( new_slide );

				if ( new_slide === ( carousel_item_count - get_items_per_page() ) ) disableButton( carousel_next );
				enableButton( carousel_prev );
		    } );

		    function disableButton ( button ) {
			    if ( button ) button.classList.add( classes.is_disabled );
			}

			function enableButton ( button ) {
			    if ( button ) button.classList.remove( classes.is_disabled );
			}

		    function get_items_per_page () {
		    	let items_per_page = this_carousel.dataset.itemsPerPage;

		    	if ( items_per_page ) {
		    		items_per_page = items_per_page.split(',');

			    	if ( items_per_page.length === 3 ) {
				    	if ( window.matchMedia( COMMON_SETTINGS.matchMediaBreakpoints.wide ).matches ) {
				    		return parseInt( items_per_page[2] );
				    	} else if ( window.matchMedia( COMMON_SETTINGS.matchMediaBreakpoints.tablet ).matches ) {
				    		return parseInt( items_per_page[1] );
				    	} else {
				    		return parseInt( items_per_page[0] );
				    	}
				    }
				}

			    return 1;
		    }

			function build_counters () {
				carousel_dots_container.innerHTML = '';

				for ( let i = 0; i < Math.ceil( carousel_item_count / get_items_per_page() ); i ++ ) {
					let new_dot = document.createElement( 'span' );

					if ( i === start_index ) new_dot.classList.add( classes.is_active );

					carousel_dots_container.append( new_dot );
				}
			}

			function update_dots () {
				const current_page = Math.ceil( carousel.currentSlide / get_items_per_page() );

				if ( current_page !== previous_slide_index ) {
					carousel_dots_container.children[ previous_slide_index ].classList.remove( classes.is_active )
					carousel_dots_container.children[ current_page ].classList.add( classes.is_active );
				}
			}

			function equal_height_items () {
				set_block_heights_to( carousel_items, 'auto' );
				set_block_heights_to( carousel_items, get_max_height_of_elements( carousel_items ) );
			}

			function get_max_height_of_elements ( elements ) {
				return Math.max( ...elements.map( element => element.offsetHeight ) );
			}

			function set_block_heights_to ( elements, block_height ) {
				elements.forEach( element => element.style.height = ( block_height + ( isNaN( block_height ) ? '' : 'px' ) ) );
			}

			window.addEventListener( 'resize', () => {
				if ( previous_window_width !== window.innerWidth ) {
					carousel.destroy( true );
					init();
				}
			} );
		}
	} );
}

init();