
	const _CONFIG_ = {
		active_class : 'active',
	};

	[ ...document.querySelectorAll( '.js-video-overlay' ) ].forEach( instance => {
		const 	trigger = instance.querySelector( '.js-video-overlay-trigger' ),
				element = instance.querySelector( '.js-video-overlay-element' ),
				close = instance.querySelector( '.js-video-overlay-close' ),
				video = instance.querySelector( '.js-video-overlay-video' ),
				toggle_overlay = () => {
					if ( element.classList.contains( _CONFIG_.active_class ) ) {
						element.classList.remove( _CONFIG_.active_class );
						video.pause();
						return;
					}
					element.classList.add( _CONFIG_.active_class );
					video.currentTime = 0;
					video.play();
				};
		
		if ( trigger && close ) {
			trigger.addEventListener( 'click', toggle_overlay );
			close.addEventListener( 'click', toggle_overlay );
		}
	} )