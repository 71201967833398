
const read_more_items = document.querySelectorAll('.js-read-more');

[...read_more_items].forEach( function ( read_more_item ) {

	const 	read_more_continued = read_more_item.querySelector('.js-read-more-continued'),
			read_more_button = read_more_item.querySelector('.js-read-more-button'),
			read_more_text = read_more_item.querySelector('.js-read-more-expand-text');

	if ( read_more_continued && read_more_button && read_more_text ) {

		read_more_continued.style.display = 'none';

		read_more_button.addEventListener('click', function ( event ) {

			if ( read_more_continued.classList.contains('is-open') ) {

				read_more_continued.classList.remove('is-open');

				read_more_continued.style.display = 'none';

				read_more_text.innerHTML = 'more';

			} else {

				read_more_continued.classList.add('is-open');

				read_more_continued.style.display = 'block';

				read_more_text.innerHTML = 'less';

			}

		} );

	}

} );