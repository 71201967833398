import Siema from 'siema';

Siema.prototype.preventLinkClick = function (selector) {
    const CONTAINER = this.selector.parentNode;
    const LINKS = CONTAINER.querySelectorAll(selector);

    LINKS.forEach(el => {
        let firstMouseX = 0;

        el.addEventListener('mousedown', (e) => firstMouseX = e.clientX);

        el.addEventListener('click', (e) => {
            let lastMouseX = e.clientX;
            let diffMouseX = firstMouseX - lastMouseX;

            if(diffMouseX > 1 || diffMouseX < -1) {
                e.preventDefault();
            }
        });
    });
}

const $case_study_carousel = document.querySelector('.js-case-study-carousel');

function disableButton(button) {
	if(button) {
		button.classList.add('is-disabled');
		button.disabled = true;
	}
}

function enableButton(button) {
	if(button) {
		button.classList.remove('is-disabled');
		button.disabled = false;
	}
}

if ( $case_study_carousel ) {

	$case_study_carousel.classList.remove('no-carousel');

    const prevButton = document.querySelector('.js-case-study-carousel-previous'),
          nextButton = document.querySelector('.js-case-study-carousel-next');

	const caseStudyCarousel = new Siema({
	    selector: '.js-case-study-carousel',
		perPage: 1,
		onInit() {
            this.preventLinkClick('li');

            disableButton(prevButton);
        },
        onChange: () => {
            if(caseStudyCarousel.currentSlide > 0) {
				enableButton(prevButton);
			} else {
				disableButton(prevButton);
			}

			if(caseStudyCarousel.currentSlide == caseStudyCarousel.perPage) {
				disableButton(nextButton);
			} else {
				enableButton(nextButton);
			}
        }
	});

	prevButton.addEventListener('click', () => caseStudyCarousel.prev());
	nextButton.addEventListener('click', () => caseStudyCarousel.next());

}
