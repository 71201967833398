// import 'nodelist-foreach-polyfill';
import detectTouchscreen from '../utils/utils.detect-touchscreen';

import COMMON_SETTINGS from '../settings/settings.common-settings';
import { mmMatches } from '../utils/utils.matchmedia';

const   siteHeader = document.querySelector('.js-site-header');

let     loginButton = null, navigation = null, navigationList = null, dropdownItems = null;

if ( siteHeader ) {

    loginButton = siteHeader.querySelector('.js-login-button');
    navigation = siteHeader.querySelector('.js-site-navigation');
    navigationList = navigation.querySelector('.js-site-navigation-list');
    dropdownItems = navigationList.querySelectorAll('.js-site-navigation-item.has-dropdown');

}
        
let initialised = false;

if ( siteHeader && navigation && navigationList && dropdownItems ) {

    init();

    (() => {
        const throttle = (type, name, object) => {
            object = object || window;
            let running = false;

            const isRunning = () => {
                if(running) {
                    return;
                }

                running = true;

                requestAnimationFrame(() => {
                    object.dispatchEvent(new CustomEvent(name));
                    running = false;
                });
            };

            object.addEventListener(type, isRunning);
        };

        throttle('resize', 'optimisedResize');
    })();

}

function navIsInitialised() {
    return initialised;
}

function determineNavType() {
	return ( window.matchMedia( COMMON_SETTINGS.matchMediaBreakpoints.tablet ).matches ) ? 'DESKTOP' : 'MOBILE';
}

function addTouchEventListeners() {
    siteHeader.addEventListener('click', () => siteHeader.classList.add('is-active'));

    dropdownItems.forEach(item => item.addEventListener('click', function (event) {

        if(!item.classList.contains('is-active')) {

            event.preventDefault();

        }

        toggle_sub_nav(this);
        
    }));
}

function headerScrollBeahaviour() {

	window.addEventListener('scroll', toggleHeaderScrolledClass);

	function toggleHeaderScrolledClass() {
		if ( window.scrollY > 25 ) {
			siteHeader.classList.add('has-scrolled');
		} else {
			siteHeader.classList.remove('has-scrolled');
		}
	}

	toggleHeaderScrolledClass();
}

function addHoverEventListeners() {
    siteHeader.addEventListener('mouseenter', function () {

        this.classList.add('is-active');

    });

    siteHeader.addEventListener('mouseleave', function () {

        this.classList.remove('is-active');

        hide_sub_nav();
    });

    dropdownItems.forEach(item => item.addEventListener('mouseenter', function () {
        toggle_sub_nav(this);
    }));

    dropdownItems.forEach(item => item.addEventListener('mouseleave', () => {
        hide_sub_nav();
    }));
}

function init() {
    if(!navIsInitialised()) {
        if(detectTouchscreen()) {
            navigation.classList.add('is-touch');
            addTouchEventListeners();
        } else {
            addHoverEventListeners();
        }

        headerScrollBeahaviour();

        initialised = true;
    }
}

function toggle_sub_nav ( event_element ) {

    const itemDropdown = event_element.querySelector('.js-sub-nav');

    if ( event_element.classList.contains('is-active') ) {

        hide_sub_nav();

        event_element.classList.remove('is-active')

    } else {

        dropdownItems.forEach( function ( dropdownItem ) {

            dropdownItem.classList.remove('is-active');            

        } )

        // if(determineNavType() === 'DESKTOP') {
        //     siteHeader.style.height = `auto`;
        // }

        event_element.classList.add('is-active');

        // if(determineNavType() === 'DESKTOP') {
        //     const siteHeaderHeight = siteHeader.offsetHeight + itemDropdown.offsetHeight;
        //     siteHeader.style.height = `${siteHeaderHeight}px`;
        // }

    }

}

function hide_sub_nav () {

    for ( let dropdownItem of dropdownItems ) {

        dropdownItem.classList.remove('is-active');

    }

    if(determineNavType() === 'DESKTOP') {
        siteHeader.style.height = "";
    }

}
