import Siema from 'siema';

Siema.prototype.preventLinkClick = function (selector) {
    const CONTAINER = this.selector.parentNode;
    const LINKS = CONTAINER.querySelectorAll(selector);

    LINKS.forEach(el => {
        let firstMouseX = 0;

        el.addEventListener('mousedown', (e) => firstMouseX = e.clientX);

        el.addEventListener('click', (e) => {
            let lastMouseX = e.clientX;
            let diffMouseX = firstMouseX - lastMouseX;

            if(diffMouseX > 1 || diffMouseX < -1) {
                e.preventDefault();
            }
        });
    });
}

const $related_articles_carousel = document.querySelector('.js-related-articles-carousel');

if ( $related_articles_carousel ) {

	const related_articles_carousel_items = $related_articles_carousel.querySelectorAll('.js-related-articles-item');

	set_heights( related_articles_carousel_items );

	const latestFeedCarousel = new Siema({
	    selector: '.js-related-articles-carousel',
	    perPage: {
	        0: 1,
	        768: 2,
	        1024: 3
		},
		onInit() {
			this.preventLinkClick('li');
		}
	});

	document.querySelector('.js-related-articles-carousel-previous').addEventListener('click', () => latestFeedCarousel.prev());
	document.querySelector('.js-related-articles-carousel-next').addEventListener('click', () => latestFeedCarousel.next());

	$related_articles_carousel.classList.add('is-carousel');

	window.addEventListener('resize', set_heights)

	function set_heights( related_articles_carousel_items ) {

		const related_articles_carousel_items_max_height = get_heights( related_articles_carousel_items );

		[...related_articles_carousel_items].forEach( function ( related_articles_carousel_item ) {

			related_articles_carousel_item.style.height = `${related_articles_carousel_items_max_height}px`;

		} )

	}

	function get_heights ( related_articles_carousel_items ) {

		let placeholder_arr = [];

		[...related_articles_carousel_items].forEach( function ( related_articles_carousel_item ) {

			placeholder_arr.push( related_articles_carousel_item.offsetHeight );

		} )

		return Math.max( ...placeholder_arr );

	}

}
