
const body = document.querySelector('body');

export function makeBodyNonScrollable() {
	
	body.style.height = '100%';
	body.style.overflow = 'hidden';
}

export function makeBodyScrollable() {
    body.style.height = 'auto';
	body.style.overflow = 'visible';
}